import classNames from "classnames";
import { useState } from "react";
import Nav from "react-bootstrap/Nav";
import { NavLink } from "react-router-dom";

import Lema from "../../lema/Lema";
import Logo from "../../logo/Logo";

import "./_menuTW.scss";

function MenuTW() {
  const toggleMenu = () => {
    setMenuOpen(!menuState);
  };

  const [menuState, setMenuOpen] = useState(true);

  return (
    <>
      <div className='mx-auto flex flex-nowrap py-2 md:py-6 justify-between'>
        <div className='mx-4 py-2 md:py-6 sm:mx-auto flex flex-nowrap items-center justify-between'>
          <NavLink to='/'>
            <Logo />
            <Lema />
          </NavLink>
        </div>
        <button
          onClick={toggleMenu}
          type='button'
          className='md:hidden text-white focus:outline-none focus:ring-2 focus:ring-blue-300 rounded-lg inline-flex items-center justify-center mr-6 flex transition-all'
        >
          <span className='sr-only'>Open main menu</span>
          <svg
            className='w-6 h-6'
            fill='#000000'
            viewBox='0 0 20 20'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              fillRule='evenodd'
              d='M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z'
              clipRule='evenodd'
            ></path>
          </svg>
          <svg
            className='hidden w-6 h-6'
            fill='currentColor'
            viewBox='0 0 20 20'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              fillRule='evenodd'
              d='M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z'
              clipRule='evenodd'
            ></path>
          </svg>
        </button>
      </div>
      <Nav
        className={classNames(
          "menu flex-row justify-center sm:flex md:py-2 md:px-5 md:bg-gradient-to-r from-teal-600 to-teal-400 transition-all",
          {
            "opacity-0 md:opacity-100": menuState,
            "md:opacity-100": !menuState,
          }
        )}
      >
        <div
          id='mobile-menu'
          className={classNames("md:block w-full md:w-auto transition-all", {
            hidden: menuState,
            "opacity-0 md:opacity-100": menuState,
            "md:opacity-100": !menuState,
          })}
        >
          <ul className='flex-col md:flex-row flex py-2 px-1 transition-all'>
            <li>
              <NavLink to='/'>
                <p>INICIO</p>
              </NavLink>
            </li>
            <li>
              <NavLink to='/ospoce'>
                <p>OSPOCE</p>
              </NavLink>
            </li>
            <li>
              <NavLink to='/nosotros'>
                <p>NOSOTROS</p>
              </NavLink>
            </li>
            <li>
              <NavLink to='/sucursales'>
                <p>SUCURSALES</p>
              </NavLink>
            </li>
          </ul>
        </div>
      </Nav>
    </>
  );
}

export default MenuTW;
