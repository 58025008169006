import { Scrollbars } from "react-custom-scrollbars-2";
import { Outlet } from "react-router-dom";
import MainContainer from "./../../components/maincontainer/MainContainer";
import Footer from "./../../components/shared/footer/Footer";
import Head from "./../../components/shared/head/Head";

export default function Root() {
  return (
    <Scrollbars
      // This will activate auto hide
      autoHide
      // Hide delay in ms
      autoHideTimeout={1000}
      // Duration for hide animation in ms.
      autoHideDuration={200}
      autoHeight
      autoHeightMin={"100vh"}
      universal
    >
      <MainContainer>
        <Head />
        <Outlet />
        <Footer />
      </MainContainer>
    </Scrollbars>
  );
}
