import "animate.css";
import Brand from "../../assets/img/MSB.svg";
import "./_nosotros.scss";

function Nosotros() {
  return (
    <div className='nosotros text-center'>
      <div className='bg-gradient-to-r from-teal-100 to-white my-4 py-6 animate__animated animate__fadeInUp'>
        <div className='brand flex flex-row items-center py-4'>
          <img className='w-32 mx-auto' src={Brand} alt='Logo' />
        </div>
        <div className='sm:mb-24 py-6'>
          <div className='flex flex-row sm:w-2/3 md:w-2/3 text-xl mx-auto px-6 py-4'>
            <h5 className='font-bold'>
              Somos una organización formada por profesionales ocupados en
              brindar asesoramiento a medida sobre salud.
            </h5>
          </div>
          <div className='flex flex-row sm:w-2/3 text-left mx-auto px-6 gap-4 py-6'>
            <div className='flex flex-col w-1/2 gap-2'>
              <p className='font-medium'>
                Entendemos que para cada persona existe un plan con la cobertura
                ideal.
              </p>
              <p className='font-bold'>
                Queremos indicarte cual es la que vos necesitas, sin pagar de
                más y permitiendo una cobertura completa de tus necesidades.
              </p>
            </div>

            <div className='flex flex-col w-1/2 gap-2'>
              <p className='font-bold'>
                Nos distingue el compromiso por el seguimiento del producto que
                comercializamos.
              </p>
              <p className='font-medium'>
                Cumplimos 15 años apoyándonos en empresas líderes para
                garantizar un servicio postventa adecuado.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Nosotros;
