import "animate.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Brands from "../brands/Brands";
import "./_banner.scss";

function Banner() {
  return (
    <div className='flex mx-auto md:w-36'>
      <Brands />
    </div>
  );
}

export default Banner;
