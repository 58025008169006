import "animate.css";
import "./_sucursales.scss";

function Sucursales() {
  return (
    <div className='sucursales text-center py-6 container mx-auto'>
      <div className=' grid md:grid-cols-3 gap-x-6'>
        <div className='bg-gradient-to-r shadow from-teal-100 to-white my-2 py-2 animate__animated animate__fadeInUp'>
          <h3 className='font-bold text-xl'>CABA</h3>
          <p className='w-1/2 my-2 mx-auto '>Viamonte 611</p>
          <p className='w-1/2 my-2 mx-auto '>15 4068-0502</p>
          <p className='my-2 mx-auto '>Info@msbroker.com.ar</p>
        </div>
        <div className='bg-gradient-to-r shadow from-teal-100 to-white my-2 py-2 animate__animated animate__fadeInUp'>
          <h3 className='font-bold text-xl'>RAMOS MEJIA</h3>
          <p className='w-1/2 my-2 mx-auto '>Alvarado 333</p>
          <p className='w-1/2 my-2 mx-auto '>15 5906-1077</p>
          <p className='my-2 mx-auto '>Fernandamsbroker@gmail.com </p>
        </div>
        <div className='bg-gradient-to-r shadow from-teal-100 to-white my-2 py-2 animate__animated animate__fadeInUp'>
          <h3 className='font-bold text-xl'>SAN ISIDRO</h3>
          <p className='w-1/2 my-2   mx-auto '>Belgrano 351</p>
          <p className='w-1/2 my-2 mx-auto '>15 2327-4476</p>
          <p className=' my-2 mx-auto '>Balthazarmsbroker@gmail.com</p>
        </div>
        <div className='bg-gradient-to-r shadow from-teal-100 to-white my-2 py-2 animate__animated animate__fadeInUp'>
          <h3 className='font-bold text-xl'>LOMAS DE ZAMORA</h3>
          <p className='w-1/2 my-2 mx-auto '>Av. Meeks 301</p>
          <p className='w-1/2 my-2 mx-auto '>15 2327-4476</p>
          <p className=' my-2 mx-auto '>Balthazarmsbroker@gmail.com </p>
        </div>
        <div className='bg-gradient-to-r shadow from-teal-100 to-white my-2 py-2 animate__animated animate__fadeInUp'>
          <h3 className='font-bold text-xl'>LA PLATA</h3>
          <p className='w-1/2 my-2 mx-auto '>Calle 11 Nro. 1018</p>
          <p className='w-1/2 my-2 mx-auto '>
            15 2327-4476
            <br />
            15 5906-1077
          </p>
          <p className='my-2 mx-auto'>
            Balthazarmsbroker@gmail.com
            <br />
            Fernandamsbroker@gmail.com
          </p>
        </div>
        <div className='bg-gradient-to-r shadow from-teal-100 to-white my-2 py-2 animate__animated animate__fadeInUp'>
          <h3 className='font-bold text-xl'>9 DE JULIO</h3>
          <p className='w-1/2 my-2 mx-auto '>Bartolomé Mitre 1083</p>
          <p className='w-1/2 my-2 mx-auto '>15 4068-0502</p>
          <p className='my-2 mx-auto '>Info@msbroker.com.ar</p>
        </div>
      </div>
    </div>
  );
}
export default Sucursales;
