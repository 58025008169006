import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import { Carousel } from "react-responsive-carousel";
import "./_homeCarousel.scss";

import dos from "../../assets/img/carousel/2.png";

function HomeCarousel() {
  return (
    <Carousel
      swipeable={true}
      showArrows={false}
      showStatus={false}
      showIndicators={false}
      showThumbs={false}
      emulateTouch={true}
      infiniteLoop={true}
      autoPlay={true}
    >
      <div>
        <img className='d-block' src={dos} alt='Second slide' />
      </div>
    </Carousel>
  );
}

export default HomeCarousel;
