import lema from "../../assets/img/UPATM.svg";
import "./_lema.scss";

function Lema() {
  return (
    <div className="lema flex justify-items-center items-center px-2 sm:px-5 mt-1 sm:mt-2">
      <img className="w-36 sm:w-60 mx-auto" src={lema} alt="MSB Lema" />
    </div>
  );
}

export default Lema;
