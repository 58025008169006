import "animate.css";
import Brand from "../../assets/img/logos/Ospoce.png";
import Actions from "../../components/actions/Actions";
import "./_ospoce.scss";

function Ospoce() {
  return (
    <div className='ospoce text-center'>
      <div className='bg-gradient-to-r from-teal-100 to-white my-4 py-6 animate__animated animate__fadeInUp'>
        <div className='brand flex flex-row items-center py-4'>
          <img className='w-32 mx-auto' src={Brand} alt='Logo' />
        </div>
        <p className='px-2 sm:w-1/2 my-4 mx-auto'>
          Por más de una década, desde Integral nos caracterizamos por mantener
          una ética, orientación al beneficiario, atención a sus necesidades y
          compromiso. Estos constituyen los principales pilares en el
          fortalecimiento de la relación con cada uno de los beneficiarios. Este
          espíritu y el trabajo diario en busca de la mejor calidad y servicio,
          es el que ha permitido nuestro crecimiento constante. La red
          prestacional de OSPOCE es Integral, con cobertura en la Ciudad
          Autónoma de Buenos Aires y el Conurbano, cuenta con una amplia
          selección de profesionales médicos y centros asistenciales a los
          cuales podrá acceder a través de nuestros diferentes planes.
        </p>
      </div>
      <Actions
        leftButtonLabel='Cartillas'
        leftButtonUrl='https://cartilla.ospoceintegral.com.ar/cartilla_integral.php'
        rightButtonLabel='Sanatorios'
        rightButtonUrl='https://www.msbroker.com.ar/assets/CUADROS-SANATORIOS-COMPARATIVOS-OSPOCE-INTEGRAL-2024.pdf'
      />
    </div>
  );
}
export default Ospoce;
