import vgl from "../../../assets/img/vgl.png";
import "./_footer.scss";

function Footer() {
  return (
    <footer className='footer'>
      <div className='flex flex-row sm:w-2/4 justify-evenly mb-20 sm:mb-0 my-4 py-1 px-6 mx-auto items-center'>
        <div className='flex flex-row'>
          <p className='font-bold opacity-75   text-xs'>© MSBROKER 2023</p>
          <p className='font-medium opacity-75   text-xs ml-2'>
            Todos los derechos reservados - Buenos Aires
          </p>
        </div>
        <a href='https://vgl.com.ar' target='_blank' rel='noreferrer'>
          <img src={vgl} className='w-6 mx-auto' alt='VGL Logo' />
        </a>
      </div>
    </footer>
  );
}

export default Footer;
