import "animate.css";
import { Link } from "react-router-dom";
import Banner from "../../components/banner/Banner";
import HomeCarousel from "../../components/carousel/HomeCarousel";
import Whatsapp from "../../components/whatsapp/Whatsapp";
import "./_home.scss";

import Ospoce from "../../assets/img/logos/Ospoce.png";

function Home() {
  return (
    <div className='home text-center'>
      <div className='mx-auto sm:w-1/3 pt-6 pb-3'>
        <HomeCarousel />
      </div>
      <div className='container flex pt-12 md:pt-24 pb-12 sm:px-6 sm:mx-auto'>
        <div className=' flex flex-col md:w-3/4 mx-auto py-2 items-center gap-6 '>
          <div className='flex flex-col mx-auto'>
            <h3 className='title text-4xl md:text-6xl font-bold pb-6 animate__animated animate__fadeInLeftBig'>
              Afiliate en el día de manera gratuita y desde tu casa
            </h3>
            <h4 className='title text-3xl md:text-5xl  animate__animated animate__fadeInLeftBig'>
              Nosotros nos encargamos de todo
            </h4>
          </div>
        </div>
      </div>
      <div className='px-4 py-3 flex flex-col justify-items-center'>
        <div className='flex justify-center center animate__animated animate__fadeInLeftBig'>
          <div className='flex flex-col px-6 border-x-white '>
            <Link to='/ospoce'>
              <div className='brand flex flex-row items-center py-4 h-32'>
                <img className='w-64 mx-auto' src={Ospoce} alt='Logo' />
              </div>
            </Link>
          </div>
        </div>
      </div>
      <div className='container flex sm:w-2/3 md:w-2/3 mx-auto pb-6 md:pb-24 px-4'>
        <div className='sm:w-2/4'>
          <h1 className='title md:text-4xl font-bold'>
            Elegí tu cobertura ideal
          </h1>
          <h2 className='title md:text-3xl'>
            Contamos con los mejores sanatorios
          </h2>
        </div>
        <div className='sm:w-2/4 flex items-center px-4'>
          <Banner />
        </div>
      </div>

      <Whatsapp />
    </div>
  );
}
export default Home;
