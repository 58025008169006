import { useRouteError } from "react-router-dom";

function Error() {
  const error = useRouteError();
  console.error(error);

  return (
    <div className='error bg-gradient-to-r from-teal-100 to-white px-4 py-6 text-center'>
      <h1 className='title'>Ups!</h1>
      <p className='font-medium'>Te pedimos disculpas, hubo un error.</p>
      <p className='font-medium pb-6'>
        <i>{error.statusText || error.message}</i>
      </p>
    </div>
  );
}

export default Error;
