import "./_notFound.scss";

function NotFound() {
  return (
    <div className='notFound bg-gradient-to-r from-teal-100 to-white px-4 py-6 text-center'>
      <h1 className='title'>Ups!</h1>
      <p className='font-medium pb-6'>
        Te pedimos disculpas, no encontramos el contenido.
      </p>
    </div>
  );
}

export default NotFound;
