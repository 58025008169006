import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import "./normalize.css";

import { createBrowserRouter, RouterProvider } from "react-router-dom";

import ErrorPage from "./pages/error/Error.jsx";
import Home from "./pages/home/Home";
import Nosotros from "./pages/nosotros/Nosotros";
import NotFound from "./pages/notFound/NotFound.jsx";
import Ospoce from "./pages/ospoce/Ospoce";
import Root from "./pages/root/Root";
import Sucursales from "./pages/sucursales/Sucursales";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        errorElement: <ErrorPage />,
        children: [
          {
            index: true,
            element: <Home />,
          },
          {
            path: "ospoce",
            element: <Ospoce />,
          },
          {
            path: "nosotros",
            element: <Nosotros />,
          },
          {
            path: "sucursales",
            element: <Sucursales />,
          },
          {
            path: "*",
            element: <NotFound />,
          },
        ],
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
