import logo from "../../assets/img/MSB.svg";
import "./_logo.scss";

function Logo() {
  return (
    <div className="logo flex items-center px-2 sm:px-5">
      <img className="w-36 sm:w-60 mx-auto" src={logo} alt="MSB Logo" />
    </div>
  );
}

export default Logo;
