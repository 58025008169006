import { Link } from "react-router-dom";

function Actions({
  leftButtonLabel,
  leftButtonUrl,
  rightButtonLabel,
  rightButtonUrl,
  rightButtonTo,
  children,
}) {
  return (
    <div
      className="flex flex-col gap-y-2 gap-x-2 xl:flex-row md:w-2/3 justify-evenly items-center px-4 mx-auto 
    animate__animated animate__fadeInUp"
    >
      {leftButtonLabel && (
        <a href={leftButtonUrl || "#"} target="_blank" rel="noreferrer">
          <button class="w-48 m-4 h-14 bg-gradient-to-r from-teal-600 to-teal-400 py-4 hover:bg-teal-400 text-white font-bold py-2 px-4 border-b-4 border-teal-700 hover:border-teal-500 rounded transition-all">
            {leftButtonLabel}
          </button>
        </a>
      )}
      {rightButtonLabel && rightButtonUrl && !rightButtonTo && (
        <a href={rightButtonUrl || "#"} target="_blank" rel="noreferrer">
          <button class="w-48 m-4 h-14 bg-gradient-to-r from-teal-600 to-teal-400 py-4 hover:bg-teal-400 text-white font-bold py-2 px-4 border-b-4 border-teal-700 hover:border-teal-500 rounded transition-all ">
            {rightButtonLabel}
          </button>
        </a>
      )}
      {rightButtonLabel && rightButtonTo && !rightButtonUrl && (
        <Link to={rightButtonTo || "#"}>
          <button class="w-48 m-4 h-14 bg-gradient-to-r from-teal-600 to-teal-400 py-4 hover:bg-teal-400 text-white font-bold py-2 px-4 border-b-4 border-teal-700 hover:border-teal-500 rounded transition-all ">
            {rightButtonLabel}
          </button>
        </Link>
      )}
      {children}
    </div>
  );
}

export default Actions;
