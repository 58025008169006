import { Carousel } from "react-responsive-carousel";

import uno from "../../assets/img/centers/logo-1.png";
import diez from "../../assets/img/centers/logo-10.png";
import once from "../../assets/img/centers/logo-11.png";
import doce from "../../assets/img/centers/logo-12.png";
import trece from "../../assets/img/centers/logo-13.png";
import catorce from "../../assets/img/centers/logo-14.png";
import quince from "../../assets/img/centers/logo-15.png";
import dieciseis from "../../assets/img/centers/logo-16.png";
import diecisiete from "../../assets/img/centers/logo-17.png";
import dieciocho from "../../assets/img/centers/logo-18.png";
import dos from "../../assets/img/centers/logo-2.png";
import tres from "../../assets/img/centers/logo-3.png";
import cuatro from "../../assets/img/centers/logo-4.png";
import cinco from "../../assets/img/centers/logo-5.png";
import seis from "../../assets/img/centers/logo-6.png";
import siete from "../../assets/img/centers/logo-7.png";
import ocho from "../../assets/img/centers/logo-8.png";
import nueve from "../../assets/img/centers/logo-9.png";

const brands = [
  uno,
  dos,
  tres,
  cuatro,
  cinco,
  seis,
  siete,
  ocho,
  nueve,
  diez,
  once,
  doce,
  trece,
  catorce,
  quince,
  dieciseis,
  diecisiete,
  dieciocho,
];

function Brands() {
  return (
    <Carousel
      swipeable={false}
      showArrows={false}
      showStatus={false}
      showIndicators={false}
      showThumbs={false}
      emulateTouch={false}
      infiniteLoop={true}
      autoPlay={true}
      interval={1500}
    >
      {brands.map((i) => {
        return (
          <div key={i}>
            <img className='d-block w-100' src={i} alt={i + "slide"} />
          </div>
        );
      })}
    </Carousel>
  );
}

export default Brands;
